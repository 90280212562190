import React, { useEffect, useState } from 'react';
import tick from './../../../../assets/images/tick.svg';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  chargeSinglePaymentViaVivaPay,
  getVoucherByVoucherCode,
} from '@/api/mutation';
import { getMember } from '@/api/mutation';
import CardVoucher from '@/common/cards/voucherCard';
import ArrowButton from '@/common/buttons/ArrowButton';
import ModalMakePayment from '@/common/payment/modalMakePayment';
import { toastError, toastSuccess } from '@/common/toasts/toast';
import { validateApplyVoucher } from '@/utils/ValidateApplyVoucher';
import moment from 'moment';
import { calculateDateFromSpecificDate } from '@/utils/CalculateDateFromSpecificDate';
import { userInfo } from 'os';
const Vouchers = (props) => {
  const [currentVoucherDetail, setCurrentVoucherDetail] = useState({});
  const [newVoucherDetail, setNewVoucherDetail] = useState({});
  const [voucherCode, setVoucherCode] = useState('');
  const [getVoucherByCode] = useLazyQuery(getVoucherByVoucherCode);
  const [modalPayment, setModalPayment] = useState(false);
  const [amount, setAmount] = useState(0);
  const [error, setError] = useState('');
  const [membershipLocation, setMembershipLocation] = useState<[]>();
  const [chargeSinglePayment, { loading: chargeSinglePaymentLoading }] =
    useMutation(chargeSinglePaymentViaVivaPay);
  const [filterExpiredVoucher, setFilterExpiredVoucher] = useState<[]>();
  const { loading, data } = useQuery(getMember, {
    variables: { memberId: props.memberId },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!loading && data) {
      if (data?.getMember?.voucherMembers?.items.length) {
        let copyData = data.getMember.voucherMembers.items.slice();
        copyData.sort((a, b) => compareDates(a.createdAt, b.createdAt));

        const filterExpiredVoucher = copyData.filter((item) => {
          const dateExpired = moment(
            calculateDateFromSpecificDate({
              type: item.voucherDetail.membershipDetail.contractLength?.type,
              amount:
                item.voucherDetail.membershipDetail.contractLength?.amount,
              date: item.createdAt,
            })
          );

          const complimentaryExpired = moment(item.createdAt).add(
            item.voucherDetail.duration,
            'days'
          );

          if (item.voucherDetail.type !== 'COMPLIMENTARY') {
            return (
              !item.status ||
              item.status !== 'CANCELLED' ||
              (item.voucherDetail.membershipDetail.contractLength?.type &&
                (moment().isBefore(dateExpired) ||
                  moment().isSame(dateExpired)))
            );
          } else {
            return (
              (moment().isBefore(complimentaryExpired) ||
                moment().isSame(complimentaryExpired)) &&
              (!item.status || item.status !== 'CANCELLED')
            );
          }
        });

        setFilterExpiredVoucher(filterExpiredVoucher);

        const currentVoucher = filterExpiredVoucher.filter(
          (item) =>
            item.voucherDetail.membershipDetail.id ===
            props.membershipDetail?.membershipId
        );

        setCurrentVoucherDetail(currentVoucher[0]?.voucherDetail);
      }
    }
  }, [data, props.membershipDetail?.membershipId]);

  const compareDates = (a, b) => {
    const dateA = new Date(a?.createdAt);
    const dateB = new Date(b?.createdAt);

    if (dateA > dateB) {
      return -1;
    }
    if (dateA < dateB) {
      return 1;
    }
    return 0;
  };
  function createMarkup(data) {
    return { __html: data };
  }

  const checkVoucherCode = () => {
    getVoucherByCode({
      fetchPolicy: 'no-cache',
      variables: {
        voucherCode: voucherCode,
      },
      onCompleted: (data) => {
        setMembershipLocation(
          data?.getVoucherByVoucherCode?.items[0]?.membershipDetail?.membershipLocations?.items?.filter(
            (item) => item?.locationId === props?.info?.homeLocationDetails?.id
          )
        );
        validateApplyVoucher(
          data,
          currentVoucherDetail,
          props.membershipDetail,
          setError,
          setNewVoucherDetail,
          setAmount,
          props.memberId,
          props.memberDetail
        );
      },
    });
  };

  const handleApplyVoucher = () => {
    const paymentInformationId = props.memberDetail?.paymentDetails?.items.find(
      (i) => i.primary
    )?.id;
    chargeSinglePayment({
      variables: {
        amount: amount,
        memberId: props?.memberId,
        paymentInformationId: paymentInformationId,
        brandId: props.memberDetail?.brandDetails?.id,
        operation: 'AVM',
        futurePaymentOption: 'CARD_ON_FILE_IS_DEFAULT',
        applyVoucherMemberArguments: {
          voucherId: newVoucherDetail?.id,
          memberId: props?.memberId,
          membershipId: newVoucherDetail?.membershipDetail?.id,
          membershipLocationId: membershipLocation[0]?.id,
        },
      },
      onCompleted: () => {
        toastSuccess('Applied Voucher successfully!');
      },
      onError: (error) => {
        toastError(`Error: ${error}`);
      },
    });
  };
  return (
    <div>
      <div className="flex  gap-x-5 sm:block">
        <div className="text-white text-start flex flex-col  gap-2 uppercase text-sm font-medium order-2 sm:mb-5 sm:items-center">
          <p>Do you have a promo code?</p>
          <div className="flex">
            <input
              className={`text-primary font-semibold rounded-md  mr-[10px] px-[6px] py-[9px] w-[147px] ${
                currentVoucherDetail?.type === 'VISIT_PASS' ||
                currentVoucherDetail?.type === 'COMPLIMENTARY'
                  ? 'pointer-events-none'
                  : ''
              }`}
              maxLength={8}
              onChange={(e) => {
                setVoucherCode(e.target.value);
                if (!e.target.value) {
                  setNewVoucherDetail({});
                }
              }}
            />

            <div
              className={`h-[37px] cursor-pointer transition-all w-[130px] hover:bg-primary hover:!border-primary border border-white rounded-md flex gotham text-[12px] uppercase items-center justify-center text-white ${
                currentVoucherDetail?.type === 'VISIT_PASS' ||
                currentVoucherDetail?.type === 'COMPLIMENTARY'
                  ? 'hover:!bg-transparent hover:!border-[#808080] !border-[#808080] !text-[#808080] pointer-events-none'
                  : ''
              } `}
              onClick={checkVoucherCode}
            >
              Apply
            </div>
          </div>
          {error && <p className="text-xs text-red-500">{error}</p>}
        </div>
        {currentVoucherDetail?.membershipDetail?.id !==
          props.membershipDetail.membershipId &&
        !Object.keys(newVoucherDetail).length ? (
          <div className="sm:mx-auto w-[450px] h-auto align-center bg-richBlack text-center items-center border border-gray rounded flex flex-col">
            <span className="uppercase heading2 text-white text-center leading-[90%] my-[15px] ">
              {props.membershipDetail?.membershipName}
            </span>
            <div>
              <span className="bebas flex sm:text-center text-[45px] text-white mb-[10px] leading-[80%]">
                <p className="bebas flex sm:text-center text-[45px] text-white  leading-[80%]">
                  {`$${(
                    props.membershipDetail?.costPrice /
                    props.membershipDetail?.paymentFrequency?.amount
                  )?.toFixed(2)} `}
                </p>
                <p className="mt-auto">
                  /{props.membershipDetail?.paymentFrequency?.type}
                </p>
              </span>
              {!props.membershipDetail?.recurring ? (
                <p className="text-[rgba(255,255,255,0.6)] text-center text-xs font-normal">
                  SINGLE PAYMENT
                </p>
              ) : (
                <div>
                  <p className="text-[rgba(255,255,255,0.6)] text-center text-xs font-normal">
                    {`$${props.membershipDetail?.costPrice?.toFixed(
                      2
                    )}  fortnightly Direct Debit`}
                  </p>
                </div>
              )}
              <div className="text-white border-t my-[20px] border-t-borderLine w-[150px] text-center w-max">
                <ul className="mb-[34px] sm:max-w-[239px] sm:mx-auto w-full flex flex-col pt-[14px] sm:gap-[8px] gap-[10px] ">
                  {props.membershipDetail?.description
                    ?.split(',')
                    .concat([
                      `Joining fee: $${props.membershipDetail.joiningFee.toFixed(
                        2
                      )}`,
                      props.membershipDetail.joiningFee2
                        ? `Activation fee: $${props.membershipDetail.joiningFee2.toFixed(
                            2
                          )}`
                        : '',
                      `Card/fob fee: $${props.membershipDetail.joiningFee3.toFixed(
                        2
                      )}`,
                    ])
                    .map((i, index) => {
                      if (i) {
                        return (
                          <li
                            key={index}
                            className={`flex items-center gap-[14px] sm:gap-[10px]'
            }`}
                          >
                            <span className="self-start w-[19px] h-[19px] flex-none rounded-[50%] flex items-center justify-center border border-white">
                              <img
                                className="w-auto h-auto"
                                src={tick}
                                alt="icon"
                              />
                            </span>
                            <span
                              className="gotham text-start font-normal sm:leading-[16px] leading-4 text-white text-sm sm:uppercase sm:text-base sm:font-bold"
                              dangerouslySetInnerHTML={createMarkup(
                                i?.replace('\n', '<br/>')
                              )}
                            ></span>
                          </li>
                        );
                      }
                    })}
                </ul>
              </div>
            </div>
          </div>
        ) : null}

        <div className="flex justify-center">
          {(Object.keys(currentVoucherDetail || {}).length &&
            currentVoucherDetail?.membershipDetail?.id ===
              props.membershipDetail.membershipId) ||
          Object.keys(newVoucherDetail).length ? (
            <CardVoucher
              setType={() => {}}
              setInfo={() => {}}
              info={{
                membershipId: props.info?.contracts.items[0].membershipId,
                memberDetails: {
                  homeLocationId: props.info?.homeLocationDetails?.id,
                },
              }}
              voucherDetail={
                Object.keys(newVoucherDetail).length
                  ? newVoucherDetail
                  : currentVoucherDetail
              }
            />
          ) : null}
        </div>
      </div>
      {Object.keys(newVoucherDetail).length && !error && voucherCode && (
        <div className="mt-[60px] w-full flex items-center justify-between">
          <div></div>
          <div
            onClick={() => {
              if (amount) {
                setModalPayment(true);
              } else {
                handleApplyVoucher();
              }
            }}
          >
            <div className="md:hidden flex">
              <ArrowButton text="next" isRight={true} />
            </div>
            <div className="md:flex hidden">
              <ArrowButton text="next" isRight={true} />
            </div>
          </div>
        </div>
      )}

      {modalPayment && (
        <ModalMakePayment
          changeMembershipData={{
            locationId: props.membershipDetail?.locationId,
          }}
          setModalMakePayment={setModalPayment}
          amount={amount}
          paymentType="AVM"
          payloadData={{
            applyVoucherMemberArguments: {
              voucherId: newVoucherDetail?.id,
              memberId: props?.memberId,
              membershipId: newVoucherDetail?.membershipDetail?.id,
              membershipLocationId: membershipLocation[0]?.id,
            },
          }}
          setStep={() => {
            toastSuccess('Applied Voucher Successfully');
          }}
          isPIF={false}
        />
      )}
    </div>
  );
};

export default Vouchers;
